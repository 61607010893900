function getElementRoot(realId) {
    const embeddedElement = document.querySelector(`[flow-id="${realId}"]`);
    
    return embeddedElement ? embeddedElement.shadowRoot : document;
}

let noFill = false;

function fillFinalInput({ event, selector, value }) {
    setTimeout(() => {
        if (noFill) return;
        
        const realId = event.detail.flowID;
        const elementRoot = getElementRoot(realId);
        const targetInput = elementRoot.querySelector(selector);
        
        if (targetInput) {
            targetInput.value = value;
            console.log('FILLED');
        } else {
            console.log('NO TARGET INPUT');
        }
    }, 100);
}

window.addEventListener('heyflow-screen-view', (event) => {
    if (event.detail.screenName === 'successful-case-screen') {
        fillFinalInput({ 
            event, 
            selector: 'input[data-label="Successful result"]', 
            value: 'Successful case'
        });
    }
    if (event.detail.screenName === 'unsuccessful-case-screen') {
        fillFinalInput({ 
            event, 
            selector: 'input[data-label="Unsuccessful result"]', 
            value: 'No adverse employment action'
        });
    }
    if (event.detail.screenName === 'unsuccessful-case-screen-2') {
        fillFinalInput({ 
            event, 
            selector: 'input[data-label="Unsuccessful result 2"]', 
            value: 'No complaints about the discrimination'
        });
    }
    if (event.detail.screenName === 'unsuccessful-case-screen-3') {
        fillFinalInput({ 
            event, 
            selector: 'input[data-label="Unsuccessful result 3"]', 
            value: 'Notify the union'
        });
    }
});

function autoSubmit(screenName) {
    const devOrigin = "https://app.heyflow.com";
    const screenNames = ['successful-case-screen', 'unsuccessful-case-screen', 'unsuccessful-case-screen-2', 'unsuccessful-case-screen-3'];
    if (screenNames.includes(screenName) && window.location.origin !== devOrigin) {
        const buttonText = 'submit-heyflow-quiz-result';
        const xpath = `//section[@name='${screenName}']//button[contains(., '${buttonText}')]`
        const button = document.evaluate(xpath, document, null, XPathResult.ANY_TYPE, null ).iterateNext();
        if(button instanceof HTMLButtonElement) {
            const container = document.querySelector(`section[name="${screenName}"]`);
            setTimeout(() => {
                const newButton = button.cloneNode(true);
                newButton.style.opacity = 0;
                container.appendChild(newButton);
                newButton.click();
                setTimeout(() => {
                    container.removeChild(newButton);
                }, 5000)
            }, 1500)
        } 
    }
    
}
function autoSubmitListener(event) {
    autoSubmit(event.detail.screenName)
}
window.addEventListener('heyflow-screen-view', autoSubmitListener);

function addMaxLengthToTextAreas() {
  const textAreas = document.querySelectorAll("textarea[id]");
  const maxLength = 1000;
  textAreas.forEach((textArea) => {
    const initialLength = textArea.value.length;
    const counter = document.createElement("span");

    counter.innerHTML = `${initialLength} / ${maxLength}`;

    counter.style.display = "block";
    counter.style.textAlign = "right";
    counter.style.paddingTop = "10px";

    textArea.parentElement.appendChild(counter);

    const handleKeyup = (e) => {
      const length = e.target.value.length;
      counter.innerHTML = `${length} / ${maxLength}`;
    };

    textArea.addEventListener("keyup", handleKeyup);

    textArea.maxLength = maxLength;
  });
}

window.addEventListener('heyflow-init', addMaxLengthToTextAreas);